import { useState, useEffect } from 'react';
import Moment from 'react-moment';
import TransactionExpanded from './TransactionExpanded';

import safeIcon from "./../icons/Safe.svg";

import optimismIcon from "./../icons/blockchain/OPTIMISM.svg";
import BSC from "./../icons/blockchain/BSC.svg";
import Ethereum from "./../icons/blockchain/Ethereum.svg";
import gnosisChainIcon from "./../icons/blockchain/Gnosis.svg";
import polygonIcon from "./../icons/blockchain/Polygon.svg";
import solanaIcon from "./../icons/blockchain/SOL.svg";

const Transaction = ({ transactions, handleClick }) => {

    const [addresses, setAddresses] = useState([])

    useEffect(() => {
        const getAddresses = async () => {
            const addressesFromServer = await fetchAddresses()
            setAddresses(addressesFromServer);
        }
        getAddresses();
    }, [])

    const fetchAddresses = async () => {
        const res = await fetch('https://cmcserver.onrender.com/addresses/')
        const data = await res.json()
        return data
    }

    const blockchainIcon = (chainName) => {
        if (Array.isArray(chainName)) {
            return [Ethereum, polygonIcon, BSC];
        }
        if (chainName.match("Optimism")) {
            return optimismIcon;
        }
        else if (chainName.match("BSC")) {
            return BSC;
        }
        else if (chainName.match("Ethereum")) {
            return Ethereum;
        }
        else if (chainName.match("Gnosis Chain")) {
            return gnosisChainIcon;
        }
        else if (chainName.match("Polygon")) {
            return polygonIcon;
        }
        else if (chainName.match("Solana")) {
            return solanaIcon;
        }
    }

    const deteremineTransactionType = (incoming, outgoing) => {
        if (incoming && outgoing) {
            return {
                type: 'Interacted',
                icon: "ri-exchange-line",
            }
        } else if (incoming) {
            return {
                type: 'Sent',
                icon: "ri-arrow-right-up-line",
            }
        } else {
            return {
                type: 'Received',
                icon: "ri-arrow-left-down-line",
            }
        }
    }

    const determindWalletType = (walletType) => {
        if (walletType === 1) {
            return <i className="ri-wallet-3-line sourceIcon"></i>
        }
        if (walletType === 2) {
            return <img src={safeIcon} className="sourceIcon" />
        }
        if (walletType === 3) {

            return <i className="ri-wallet-line sourceIcon"></i>
        }
    }

    const deteremineMultirecipients = (multiRecipient, recipents) => {
        let recipientName = ""
        if (multiRecipient) {
            return recipents.length + " recipients";
        } else if (typeof recipents === "string") {
            return `${recipents.slice(0, 5)}...${recipents.slice(-5)}`;
        } else if (typeof recipents === "object") {
            addresses.forEach(address => {
                if(recipents.addressId === address.id)  {
                    recipientName = address.name
                }
            })
            return recipientName;
        }
        return null;
    }

    const deteremineRecipientIcon = (multiRecipient, recipents) => {
        if (multiRecipient) {
            return <i className="ri-group-line destinationIcon"></i>;
        } else if (typeof recipents === "string") {
            return <i className="ri-hashtag destinationIcon"></i>
        } else if (typeof recipents === "object") {
            return <i className="ri-user-line destinationIcon"></i>
        }
        return null;
    }

    console.log(transactions)

    return (

        <>
            {transactions.map((transaction) => (
                <div className={`transactionContainer ${transaction.expanded ? 'expanded' : ''}`} key={transaction.id} id={transaction.id} onClick={() => { handleClick(transaction.id) }}>
                    <div className={`transaction ${transaction.titleTransaction ? 'titleTransaction' : ''}`}>
                        <div className="typeTime">
                            <div className="timeContainer">
                                {transaction.moreThan3 ?
                                    <Moment className="time" date={transaction.timestamp} fromNow /> :
                                    <Moment className="time" date={transaction.timestamp} format='MMM Do YYYY' />
                                }
                                <Moment className="hoverTime" date={transaction.timestamp} format='MMM Do YYYY, HH:mm' />
                            </div>
                            <div className="type">
                                <i className={`${deteremineTransactionType(transaction.incoming, transaction.outgoing).icon} typeIcon`}></i>
                                <div className="typeLabel">
                                    {deteremineTransactionType(transaction.incoming, transaction.outgoing).type}
                                </div>
                            </div>
                        </div>
                        <div className="source">
                            <div className="sourceIconsContainer">
                                {determindWalletType(transaction.walletType)}
                                {transaction.blockchain === "Gnosis chain" ?
                                    <i className="cf cf-gno" style={{
                                        fontSize: "16px", position: "absolute",
                                        left: "10px",
                                        bottom: "10px",
                                        color: "#34785E",
                                        borderRadius: "100%",
                                        backgroundColor: "white"
                                    }}></i>
                                    :
                                    <img src={blockchainIcon(transaction.blockchain)} className="networkIcon" />
                                }
                            </div>
                            <div className="sourceLabel">{transaction.wallet.charAt(0).toUpperCase() + transaction.wallet.slice(1)}</div>
                        </div>
                        <div className="destination">
                            {deteremineRecipientIcon(transaction.multiRecipient, transaction.recipents)}
                            <div className="destinationLabel">
                                {deteremineMultirecipients(transaction.multiRecipient, transaction.recipents)}
                            </div>
                        </div>

                        <div className="incoming" style={{ visibility: transaction.incoming == true ? 'visible' : 'hidden' }}>
                            <div className="tokenValue">{transaction.inTokens} {transaction.inTokenSymbol}</div>
                            <div className="usdContainer">
                                <div className="usd">${Math.trunc(transaction.inTokens * transaction.inUSDPrice)}</div>
                                <div className="cents">{((transaction.inTokens * transaction.inUSDPrice).toFixed(2)).toString().substring(((transaction.inTokens * transaction.inUSDPrice).toFixed(2)).toString().indexOf("."))}</div>
                            </div>
                        </div>


                        <div className="outgoing" style={{ visibility: transaction.outgoing == true ? 'visible' : 'hidden' }}>
                            <div className="tokenValue">{transaction.outTokens} {transaction.outTokenSymbol}</div>
                            <div className="usdContainer">
                                <div className="negative">-</div>
                                <div className="usd">${Math.trunc(transaction.outTokens * transaction.outUSDPrice)}</div>
                                <div className="cents">{((transaction.outTokens * transaction.outUSDPrice).toFixed(2)).toString().substring(((transaction.outTokens * transaction.outUSDPrice).toFixed(2)).toString().indexOf("."))}</div>
                            </div>
                        </div>



                        <div className="classification">
                            <div className="classificationLabel">{transaction.classification}</div>
                            <i className="ri-arrow-down-s-line classificationDropdown"></i>
                        </div>
                    </div>
                    <TransactionExpanded transaction={transaction} />
                </div>
            ))}

        </>

    )
}

export default Transaction
