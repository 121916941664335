import { useState, useEffect } from 'react';

const AssetExpanded = ({ wallets }) => {

    const [cryptos, setCryptos] = useState([])

  useEffect(() => {
    const getCryptos = async () => {
      const cryptosFromServer = await fetchCryptos()
      setCryptos(cryptosFromServer);
    }
    getCryptos();
  }, [])

  const fetchCryptos = async () => {
    const res = await fetch('https://cmcserver.onrender.com/cryptocurrencies')
    const data = await res.json()
    return data
  }

    let asset = {}
    let assetArray = []
    wallets.forEach(wallet => {
        cryptos.forEach(crypto => {
            if (wallet.assetId === crypto.id) {
                asset = {
                    assetId: wallet.assetId,
                    tokens: wallet.balance,
                    symbol: crypto.symbol,
                    walletId: wallet.walletId,
                    walletType: wallet.walletType,
                    walletName: wallet.name,
                    usd: wallet.balance * crypto.quote.USD.price,
                }
                assetArray.push(asset);
            }
        })
    });

    return (

        <>

            {
                assetArray.map((asset) => (

                    <div className="walletsList" key={asset.walletId}>
                        <div className="wallet">
                            <div className="walletAsset">
                                <div className="tokens">{asset.tokens} {asset.symbol}</div>
                                <div className="usdContainer">
                                    <div className="usd">${Math.trunc(asset.usd)}</div>
                                    <div className="cents">{((asset.usd).toFixed(2)).toString().substring(((asset.usd).toFixed(2)).toString().indexOf("."))}</div>
                                </div>
                            </div>
                            <div className="walletName">
                                <i className="ri-wallet-line walletIcon"></i>
                                <div className="walletLabelContainer">
                                    <div className="walletLabel">{asset.walletName.charAt(0).toUpperCase() + asset.walletName.slice(1)}</div>
                                    {/* <div className="walletShare">41%</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }



        </>
    )
}

export default AssetExpanded