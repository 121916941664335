import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import Proposal from "./Proposal"
import Transaction from './Transaction';
import Asset from './Asset';
import PolicyDetails from './PolicyDetails'
import FixedActivities from './FixedActivities'

import emptyAsset from "./../icons/illustration/emptyAssets.svg"
import emptyTransactions from "./../icons/illustration/emptyTransactions.svg"
import emptyTransactionsBg from "./../icons/illustration/emptyTransactionsBg.svg"

const WalletDetails = ({ sideMenuBlur, toggleUnavailable }) => {
    const params = useParams()

    const [toggleState, setToggleState] = useState(1)
    const [proposalToggleState, setProposalToggleState] = useState(1)
    const [walletDetails, setWalletDetails] = useState([])

    useEffect(() => {
        const getWallets = async () => {
            const walletsFromServer = await fetchWallets()
            setWalletDetails(walletsFromServer);
        }
        getWallets();
    }, [])

    const fetchWallets = async () => {
        const res = await fetch(`https://cmcserver.onrender.com/wallet/${params.id}`)
        const data = await res.json()
        return data
    }

    const [members, setMembers] = useState([])

    useEffect(() => {
        const getMembers = async () => {
            const membersFromServer = await fetchMembers()
            setMembers(membersFromServer);
        }
        getMembers();
    }, [])

    const fetchMembers = async () => {
        const res = await fetch(`https://cmcserver.onrender.com/members/?wallet=${params.id}`)
        const data = await res.json()
        return data
    }

    const [assets, setAssets] = useState([])

    useEffect(() => {
        const getAssets = async () => {
            const assetsFromServer = await fetchAssets()
            setAssets(assetsFromServer);
        }
        getAssets();
    }, [])

    const fetchAssets = async () => {
        const res = await fetch(`https://cmcserver.onrender.com/assets/?walletId=${params.id}`)
        const data = await res.json()
        return data
    }

    const [transactions, setTransactions] = useState([])

    useEffect(() => {
        const getTransactions = async () => {
            const transactionsFromServer = await fetchTransactions()
            setTransactions(transactionsFromServer.transactions);
        }
        getTransactions();
    }, [])

    const fetchTransactions = async () => {
        const res = await fetch(`https://cmcserver.onrender.com/transactions/?page=0&walletId=${params.id}`)
        const data = await res.json()
        return data
    }


    const [needsApproval, setNeedsApproval] = useState([])

    const [readyToExecute, setReadyToExecute] = useState([])

    const [discarded, setDiscarded] = useState([])

    useEffect(() => {
        const getNeedsApproval = async () => {
            const needsApprovalFromServer = await fetchNeedsApproval()
            setNeedsApproval(needsApprovalFromServer.needsApproval);
        }
        getNeedsApproval();
    }, [])

    const fetchNeedsApproval = async () => {
        const res = await fetch(`https://cmcserver.onrender.com/proposals/needs-approval/?page=0&walletId=${params.id}`)
        const data = await res.json()
        return data
    }

    useEffect(() => {
        const getReadyToExecute = async () => {
            const readyToExecuteFromServer = await fetchReadyToExecute()
            setReadyToExecute(readyToExecuteFromServer.readyToExecute);
        }
        getReadyToExecute();
    }, [])

    const fetchReadyToExecute = async () => {
        const res = await fetch(`https://cmcserver.onrender.com/proposals/ready-to-execute/?page=0&walletId=${params.id}`)
        const data = await res.json()
        return data
    }

    useEffect(() => {
        const getDiscarded = async () => {
            const discardedFromServer = await fetchDiscarded()
            setDiscarded(discardedFromServer.discarded);
        }
        getDiscarded();
    }, [])

    const fetchDiscarded = async () => {
        const res = await fetch(`https://cmcserver.onrender.com/proposals/discarded/?page=0&walletId=${params.id}`)
        const data = await res.json()
        return data
    }

    const handleAssetClick = uniqueId => {
        let newAssets = []
        newAssets = assets.map(asset =>
            asset.uniqueId === uniqueId
                ? {
                    ...asset,
                    expanded: !asset.expanded
                }
                : {
                    ...asset,
                    expanded: false
                }
        )
        setAssets(newAssets)
    };

    const handleTransactionClick = transactionId => {
        let newTransactions = []
        newTransactions = transactions.map(transaction =>
            transaction.id === transactionId
                ? {
                    ...transaction,
                    expanded: !transaction.expanded
                }
                : {
                    ...transaction,
                    expanded: false
                }
        )
        setTransactions(newTransactions)
    };

    const navigate = useNavigate()

    const onBackClick = () => {
        navigate(`/wallets`);
    }

    const setTab = (index) => {
        setToggleState(index)
    }

    const setProposalTab = (index) => {
        setProposalToggleState(index)
    }


    const onNeedsApprovalViewAll = () => {
        navigate(`/proposals/?tab=1&page=0&walletId=${params.id}`);
    }

    const onReadyToExecuteViewAll = () => {
        navigate(`/proposals/?tab=2&page=0&walletId=${params.id}`);
    }

    const onDiscardedViewAll = () => {
        navigate(`/proposals/?tab=3&page=0&walletId=${params.id}`);
    }

    const onAssetsViewAll = () => {
        navigate(`/assets/?walletId=${params.id}`);
    }

    const onTransactionsViewAll = () => {
        navigate(`/transactions/?page=0&walletId=${params.id}`);
    }


    return (
        <div className={`walletDetailsContainer ${sideMenuBlur ? 'blur' : ''}`}>
            <FixedActivities toggleUnavailable={toggleUnavailable}/>
            <div className='titleActions'>
                <button className='textbtn small iconLeft neutral' onClick={onBackClick}><i className="ri-arrow-left-s-line icon"></i> <div className='label'>Wallets</div></button>
                <div className='titleContainer'>
                    <div className='title'>
                        <i className="ri-wallet-line titleIcon"></i>
                        <div className="titleLabel">{walletDetails.name}</div>
                    </div>
                    <div className='actions'>
                        <button className='filledbtn largeFilledBtn neutralFilledBtn iconLeft' onClick={() => toggleUnavailable()} ><i className="ri-qr-code-line icon"></i> <div className='label'>Make deposit</div></button>
                        <button className='filledbtn largeFilledBtn brandFilledBtn iconLeft' onClick={() => toggleUnavailable()} ><i className="ri-send-plane-line icon"></i> <div className='label'>Send tokens</div></button>
                    </div>
                </div>
            </div>
            <div className='viewContainer'>
                <div className='viewController'>
                    <div className={`tab ${toggleState === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>
                        <div className='tabTitle'>Proposals</div>
                        <div className='tabCount'>{readyToExecute.length + discarded.length + needsApproval.length}</div>
                    </div>
                    <div className={`tab ${toggleState === 2 ? 'active' : ''}`} onClick={() => setTab(2)}>
                        <div className='tabTitle'>Assets</div>
                    </div>
                    <div className={`tab ${toggleState === 3 ? 'active' : ''}`} onClick={() => setTab(3)}>
                        <div className='tabTitle'>Transactions</div>
                    </div>
                    <div className={`tab ${toggleState === 4 ? 'active' : ''}`} onClick={() => setTab(4)}>
                        <div className='tabTitle'>Policy</div>
                    </div>
                </div>
                <div className='viewContent'>
                    <div className={`proposals ${toggleState === 1 ? 'activeTab' : ''}`}>
                        {needsApproval.length === 0 && readyToExecute.length === 0 && discarded.length === 0 ?

                            <div className='emptyTransactionsContainer'>
                                <div className='emptyTranscations'>
                                    <img className="transactionsIllustration" src={emptyTransactions} />
                                    <img className="transactionsIllustrationMain" src={emptyTransactionsBg} />
                                    <div className='titleContainer'>
                                        <div className='title'>No active proposals.</div>
                                        <div className='subtitle'>All proposals will appear here.</div>
                                    </div>
                                    <button className='filledbtn largeFilledBtn neutralFilledBtn iconLeft' style={{ zIndex: "1" }}><i className="ri-send-plane-line icon"></i> <div className='label'>Send tokens</div></button>
                                </div>
                            </div>
                            : 
                            <div className='viewContainer'>
                            <div className='viewController'>
                                <div className="proposalsTabs">
                                    <div className={`proposalsTab ${proposalToggleState === 1 ? 'active' : ''}`} onClick={() => setProposalTab(1)}>
                                        Needs approval
                                        <div className='proposalsTabCount'>
                                            {needsApproval.length}
                                        </div>
                                    </div>
                                    <div className={`proposalsTab ${proposalToggleState === 2 ? 'active' : ''}`} onClick={() => setProposalTab(2)}>
                                        Ready to execute
                                        <div className='proposalsTabCount'>
                                            {readyToExecute.length}
                                        </div>
                                    </div>
                                    <div className={`proposalsTab ${proposalToggleState === 3 ? 'active' : ''}`} onClick={() => setProposalTab(3)}>
                                        Discarded
                                        <div className='proposalsTabCount'>
                                            {discarded.length}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='viewContent'>
                                <div className={`proposals ${proposalToggleState === 1 ? 'activeTab' : ''}`}>

                                    <div className="transactionsList">
                                        <div className="transactionsHeader">
                                            <div className="typeTime">Initiated</div>
                                            <div className="walletNetwork">Wallet & Network</div>
                                            <div className="toFrom">To</div>
                                            <div className="in">Tokens</div>
                                            <div className="out">Classification</div>
                                            <div className="classification">Status</div>
                                        </div>

                                        <Proposal proposals={needsApproval.slice(0, 10)} toggleUnavailable={toggleUnavailable}/>
                                        {needsApproval.length > 10 ?
                                            <button className='viewAll textbtn neutral iconRight large' onClick={() => onNeedsApprovalViewAll()}>
                                                <div className='label'>View all</div>
                                                <i className="ri-arrow-right-s-line icon"></i>
                                            </button>
                                            : ''}
                                    </div>
                                </div>
                                <div className={`proposals ${proposalToggleState === 2 ? 'activeTab' : ''}`}>

                                    <div className="transactionsList">
                                        <div className="transactionsHeader">
                                            <div className="typeTime">Initiated</div>
                                            <div className="walletNetwork">Wallet & Network</div>
                                            <div className="toFrom">To</div>
                                            <div className="in">Tokens</div>
                                            <div className="out">Classification</div>
                                            <div className="classification">Status</div>
                                        </div>

                                        <Proposal proposals={readyToExecute.slice(0, 10)} toggleUnavailable={toggleUnavailable}/>
                                        {readyToExecute.length > 10 ?
                                            <button className='viewAll textbtn neutral iconRight large' onClick={() => onReadyToExecuteViewAll()}>
                                                <div className='label'>View all</div>
                                                <i className="ri-arrow-right-s-line icon"></i>
                                            </button>
                                            : ''}
                                    </div>
                                </div>
                                <div className={`proposals ${proposalToggleState === 3 ? 'activeTab' : ''}`}>
                                    <div className="transactionsList">
                                        <div className="transactionsHeader">
                                            <div className="typeTime">Initiated</div>
                                            <div className="walletNetwork">Wallet & Network</div>
                                            <div className="toFrom">To</div>
                                            <div className="in">Tokens</div>
                                            <div className="out">Classification</div>
                                            <div className="classification">Status</div>
                                        </div>

                                        <Proposal proposals={discarded.slice(0, 10)} toggleUnavailable={toggleUnavailable}/>
                                        {discarded.length > 10 ?
                                            <button className='viewAll textbtn neutral iconRight large' onClick={() => onDiscardedViewAll()}>
                                                <div className='label'>View all</div>
                                                <i className="ri-arrow-right-s-line icon"></i>
                                            </button>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                            }

                    </div>

                    <div className={`assetsList ${toggleState === 2 ? 'activeTab' : ''}`}>


                        {assets.length === 0 ?
                            <div className='emptyAssetsContainer'>
                                <div className='emptyAssets'>
                                    <img className="assetIllustration" src={emptyAsset} />

                                    <div className='qrCodeContainer'>

                                        <QRCodeSVG className='qrCode' value={walletDetails.address} fgColor="#8E8E8E" />
                                    </div>
                                    <div className='titleContainer'>

                                        <div className='title'>{walletDetails.name} does not hold any asset.</div>
                                        <div className='subTitleContainer'>
                                            <div className='subIcons'>

                                            </div>
                                            <div className='subtitle' onClick={() => { navigator.clipboard.writeText(walletDetails.address) }}>{walletDetails.address}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div className="assetsHeaders">
                                    <div className="tokensNetwork">Tokens & Network</div>
                                    <div className="priceTrend">
                                        <div className="price">Price</div>
                                        <div className="trend">Last 24 hours</div>
                                    </div>
                                    <div className="tokenValueContainer">
                                        <div className="tokenTitle">Token Value</div>
                                        <div className="usdTitle">USD</div>
                                    </div>
                                    <div className="wallets">Wallets</div>
                                </div>
                                <Asset assets={assets.slice(0, 10)} wallets={walletDetails} handleAssetClick={handleAssetClick} />
                                {assets.length > 10 ?
                                    <button className='viewAll textbtn neutral iconRight large' onClick={() => onAssetsViewAll()}>
                                        <div className='label'>View all</div>
                                        <i className="ri-arrow-right-s-line icon"></i>
                                    </button>
                                    : ''}
                            </>
                        }

                    </div>
                    <div className={`transactionsList ${toggleState === 3 ? 'activeTab' : ''}`}>
                        {transactions.length === 0 ?
                            <div className='emptyTransactionsContainer'>
                                <div className='emptyTranscations'>
                                    <img className="transactionsIllustration" src={emptyTransactions} />
                                    <img className="transactionsIllustrationMain" src={emptyTransactionsBg} />
                                    <div className='titleContainer'>

                                        <div className='title'>Start by proposing a token transfer.</div>
                                        <div className='subtitle'>Transfers once processed will appear under transactions</div>

                                    </div>
                                    <button className='filledbtn largeFilledBtn neutralFilledBtn iconLeft' style={{ zIndex: "1" }}><i className="ri-send-plane-line icon"></i> <div className='label'>Send tokens</div></button>
                                </div>
                            </div>
                            :
                            <>
                                <div className="transactionsHeader">
                                    <div className="typeTime">Type & Time</div>
                                    <div className="walletNetwork">Wallet & Network</div>
                                    <div className="toFrom">To/From</div>
                                    <div className="in">In</div>
                                    <div className="out">Out</div>
                                    <div className="classification">Classification</div>
                                </div>
                                <Transaction transactions={transactions.slice(0, 10)} handleClick={handleTransactionClick} />
                                {transactions.length > 10 ?
                                    <button className='viewAll textbtn neutral iconRight large' onClick={() => onTransactionsViewAll()}>
                                        <div className='label'>View all</div>
                                        <i className="ri-arrow-right-s-line icon"></i>
                                    </button>
                                    : ''}
                            </>
                        }

                    </div>
                    <div className={`policyContainer ${toggleState === 4 ? 'activeTab' : ''}`}>
                        <PolicyDetails walletDetails={walletDetails} members={members} />
                    </div>
                </div>
            </div>


        </div>
    )
}
export default WalletDetails
